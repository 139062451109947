import Vue from 'vue'
import Vuex from 'vuex'
import actions from "@/store/actions";
import mutations from "@/store/mutations";
import getters from "@/store/getters";
import modules from "@/store/modules";

Vue.use(Vuex)

const state = sessionStorage.getItem('state') ? JSON.parse(sessionStorage.getItem('state')) : {
  token: "",
  options: [{
    title: '主页',
    name: 'home',
    closable:false
  }],
  activeIndex: 'home'
}

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules
})
