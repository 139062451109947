export default {
    //添加token
    mGetToken(state, payload) {
        this.state.token = payload
    },

    // 添加tabs
    add_tabs (state, data) {
        this.state.options.push(data);
    },

    // 删除tabs
    delete_tabs (state, route) {
        let index = 0;
        for (let option of state.options) {
            if (option.name === route) {
                break;
            }
            index++;
        }
        this.state.options.splice(index, 1);
    },

    // 设置当前激活的tab
    set_active_index (state, index) {
        this.state.activeIndex = index;
    }

}