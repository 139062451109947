import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// 在VUE中路由遇到Error: Avoided redundant navigation to current location:报错显示是路由重复,这是多次访问路由形成的
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(err => err)
}

const routes = [
    {
        path: '/',
        redirect: '/login',
        meta: {
            title: '管理系统',
        },
    },
    {
        path: '/index',
        name: 'Index',
        redirect: '/blogmain',
        component: () => import('@/views/web/blog/Index'),
        meta: {
            title: '技术博客',
        },
        children: [
            {
                path: '/blogmain',
                name: 'BlogMain',
                component: () => import('@/views/web/blog/BlogMain'),
                meta: {
                    title: '博客首页',
                }
            },
            {
                path: '/article',
                name: 'Article',
                component: () => import('@/views/web/blog/Article'),
                meta: {
                    title: '文章详情',
                }
            },
            {
                path: '/tool',
                name: 'Tool',
                component: () => import('@/views/web/blog/Tool'),
                meta: {
                    title: '工具类下载',
                }
            },
            {
                path: '/about',
                name: 'About',
                component: () => import('@/views/web/blog/About'),
                meta: {
                    title: '关于',
                }
            },
            {
                path: '/lmessage',
                name: 'Lmessage',
                component: () => import('@/views/web/blog/Lmessage'),
                meta: {
                    title: '留言',
                }
            },
            {
                path: '/tag',
                name: 'Tag',
                component: () => import('@/views/web/blog/Tag'),
                meta: {
                    title: '文章标签',
                }
            },
            {
                path: '/classify',
                name: 'Classify',
                component: () => import('@/views/web/blog/Classify'),
                meta: {
                    title: '文章分类',
                }
            },
            {
                path: '/search',
                name: 'Search',
                component: () => import('@/views/web/blog/Search'),
                meta: {
                    title: '文章搜索',
                }
            }
        ]
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/home/Login'),
        meta: {
            title: '管理系统-用户登录',
        }
    },
    {
        path: '/main',
        name: 'Main',
        redirect: '/home',
        component: () => import('@/views/home/Main'),
        meta: {
            title: '首页',
        },
        children:
            [
                {
                    path: '/home',
                    name: 'Home',
                    component: () => import('@/views/home/Home'),
                    meta: {
                        title: '主页',
                    }
                },
                {
                    path: '/user',
                    name: 'User',
                    component: () => import('@/views/user/User'),
                    meta: {
                        title: '用户管理',
                    }
                },
                {
                    path: '/role',
                    name: 'Role',
                    component: () => import('@/views/user/Role'),
                    meta: {
                        title: '角色管理',
                    }
                },
                {
                    path: '/dept',
                    name: 'Dept',
                    component: () => import('@/views/user/Dept'),
                    meta: {
                        title: '部门管理',
                    }
                },
                {
                    path: '/unit',
                    name: 'Unit',
                    component: () => import('@/views/user/Unit'),
                    meta: {
                        title: '单位管理',
                    }
                },
                {
                    path: '/menu',
                    name: 'Menu',
                    component: () => import('@/views/user/Menu'),
                    meta: {
                        title: '菜单管理',
                    }
                },
                {
                    path: '/number',
                    name: 'Number',
                    component: () => import('@/views/user/Number'),
                    meta: {
                        title: '菜单管理',
                    }
                },
                {
                    path: '/blabel',
                    name: 'Blabel',
                    component: () => import('@/views/blog/Blabel'),
                    meta: {
                        title: '标签管理',
                    }
                },
                {
                    path: '/barticle',
                    name: 'Barticle',
                    component: () => import('@/views/blog/Barticle'),
                    meta: {
                        title: '发布文章',
                    }
                }
            ]
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    next()
    document.title ='张中正个人博客|技术博客-'+to.meta.title
})

export default router
